.PrintingTable {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: flex-start;
  font-size: 9pt;
  font-family: "Arial", sans-serif;
}
.PrintingTable .PrintingTable__table-body {
  width: 100%;
  border: 0.01cm solid #141414;
  padding: 0;
  margin: 0;
  display: table;
  border-collapse: collapse;
  gap: 0;
}
.PrintingTable .PrintingTable__table-body tbody {
  width: 100%;
  display: table-row-group;
}
.PrintingTable .PrintingTable__table-body thead {
  width: 100%;
  display: table-header-group;
}
.PrintingTable .PrintingTable__table-body td, .PrintingTable .PrintingTable__table-body th {
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  border: 0.01cm solid #141414;
  display: table-cell;
  padding: 0.1cm;
}
.PrintingTable .PrintingTable__table-body tbody tr td:nth-child(6) {
  text-wrap: nowrap;
  white-space: nowrap;
}