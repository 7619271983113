.PassangersList .PassangerList__input-container .PassangerList__input {
  width: 100%;
  height: 42px;
  padding: 10px 15px;
  color: #353535;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #8B8B8B;
  background: #FFF;
  outline: none;
}

.PassangersList .PassangerList__input-container .PassangerList__input-placeholder {
  position: absolute;
  left: 10px;
  top: 0;
  background: #ffffff;
  padding: 0 5px;
  transform: translate(0, -50%);
  color: #646464;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

.PassangersList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
}
.PassangersList * {
  font-family: "Roboto", sans-serif;
}
.PassangersList .PassangersList__headline {
  color: #0F0F0F;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.PassangersList .PassangersList__headline .PassangersList__headline_number {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFCB46;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.PassangersList .PassangerList__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.PassangersList .PassangerList__input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}