.Footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 350px;
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #FFCB46;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 1000px) {
  .Footer {
    padding: 20px;
  }
}
@media screen and (max-width: 900px) {
  .Footer {
    display: none;
  }
}