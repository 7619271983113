.ViewTrip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.ViewTrip .Autobus .Autobus__container .Autobus__column .Autobus__group .Autobus__entrance {
  width: 112px;
  left: 43px;
}
.ViewTrip .ViewTrip__btn {
  height: auto;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  padding: 7px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid #FFCB46;
  color: #FFCB46;
}
.ViewTrip .ViewTrip__btn_filled {
  background: #FFCB46;
  color: #ffffff;
  border: none;
}
.ViewTrip .ViewTrip__btn_filled img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.ViewTrip .ViewTrip__actions {
  width: 100%;
  padding: 10px 0;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}
.ViewTrip .ViewTrip__actions .ViewTrip__action {
  height: 40px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ViewTrip .ViewTrip__actions .ViewTrip__action_green {
  background: rgba(48, 184, 0, 0.59);
}
.ViewTrip .ViewTrip__actions .ViewTrip__action_red {
  background: rgba(255, 10, 10, 0.75);
}
.ViewTrip .ViewTrip__headline {
  width: 100%;
  margin-top: 15px;
  color: #353535;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.ViewTrip .ViewTrip__records {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ViewTrip .ViewTrip__records .ViewTrip__record {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.ViewTrip .ViewTrip__records .ViewTrip__record .ViewTrip__record-head {
  width: 100%;
  height: 34px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3D3D3D;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}
.ViewTrip .ViewTrip__records .ViewTrip__record .ViewTrip__record-body {
  width: 100%;
  padding: 12px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ViewTrip .ViewTrip__records .ViewTrip__record .ViewTrip__record-body .ViewTrip__record-headline {
  color: #353535;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ViewTrip .ViewTrip__records .ViewTrip__record .ViewTrip__record-body .ViewTrip__record-info {
  color: #353535;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ViewTrip .ViewTrip__records .ViewTrip__record .ViewTrip__record-body .ViewTrip__sub-passanger {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #8B8B8B;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.ViewTrip .ViewTrip__records .ViewTrip__record .ViewTrip__record-body .ViewTrip__sub-passanger .ViewTrip__record-head {
  width: 100%;
  height: 34px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3D3D3D;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}
.ViewTrip .ViewTrip__records .ViewTrip__record .ViewTrip__record-body .ViewTrip__sub-passanger_initiator .ViewTrip__record-head {
  background: #FFCB46;
}
.ViewTrip .ViewTrip__records .ViewTrip__record_initiator .ViewTrip__record-head {
  background: #FFCB46;
}
.ViewTrip .ViewTrip__records .ViewTrip__record_initiator .ViewTrip__sub-passanger_initiator .ViewTrip__record-head {
  background: #FFCB46;
}