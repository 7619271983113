.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@media screen and (max-width: 919px) {
  .App {
    justify-content: flex-start;
  }
}
@media print {
  .Header {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    visibility: hidden;
    overflow: hidden;
  }
  .Account {
    margin-top: 0;
    margin: 0;
  }
  .Account .Account__menu {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    overflow: hidden;
  }
  .Account .TripsControll {
    display: none;
  }
  .Account .AllUsersData {
    width: 100%;
    z-index: 10000;
    display: flex;
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .Account .AllUsersData .AllUsersData__head {
    display: none;
  }
  .Account .AllUsersData .AllUsersData__search {
    display: none;
  }
  .Account .AllUsersData .AllUserData__container {
    display: none;
  }
  .Account .AllUsersData * {
    display: flex;
  }
  .Account .PrintingTable {
    display: flex;
    position: relative;
    width: calc(100vw - 0.3cm);
    left: -0.5cm;
  }
  .Account .PrintingTable .PrintingTable__table-body {
    display: table;
  }
  .Account .PrintingTable .PrintingTable__table-body * {
    position: static;
  }
  .Account .PrintingTable .PrintingTable__table-body tr {
    display: table-row;
  }
  .Account .PrintingTable .PrintingTable__table-body tbody {
    display: table-row-group;
  }
  .Account .PrintingTable .PrintingTable__table-body td, .Account .PrintingTable .PrintingTable__table-body th {
    display: table-cell;
  }
  .Footer {
    display: none;
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
  }
}