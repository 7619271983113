.TripCard {
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #AEAEAE;
  background: #FBFBFB;
  padding: 10px;
}
.TripCard * {
  font-family: "Roboto", sans-serif;
}
.TripCard .TripCard__locations {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.TripCard .TripCard__locations .TripCard__group {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.TripCard .TripCard__locations .TripCard__group .TripCard__info {
  color: #353535;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.TripCard .TripCard__locations .TripCard__group .TripCard__info_bold {
  color: #000;
  font-weight: 700;
}
.TripCard .TripCard__locations .TripCard__group .TripCard__info_city {
  color: #353535;
}
.TripCard .TripCard__locations .TripCard__group .TripCard__sign {
  width: auto;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
.TripCard .TripCard__controll {
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.TripCard .TripCard__controll .TripCard__headline {
  color: #353535;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}
.TripCard .TripCard__controll .TripCard__places-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
}
.TripCard .TripCard__controll .TripCard__places-info_red {
  color: #DE6107;
}
.TripCard .TripCard__controll .TripCard__places-info_green {
  color: #19760A;
}
.TripCard .TripCard__controll .TripCard__controll-btn-place {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}
.TripCard .TripCard__controll .TripCard__controll-btn-place .TripCard__controll-btn {
  height: 40px;
  padding: 13px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Arial", sans-serif;
}
.TripCard .TripCard__controll .TripCard__controll-btn-place .TripCard__controll-btn_red {
  background: rgba(255, 10, 10, 0.75);
}
.TripCard .TripCard__controll .TripCard__controll-btn-place .TripCard__controll-btn_orange {
  background: #FFCB46;
}
.TripCard .TripCard__controll .TripCard__controll-btn-place .TripCard__controll-btn_green {
  background: rgba(48, 184, 0, 0.59);
}
.TripCard .TripCard__controll .TripCard__controll-btn-place .TripCard__controll-btn_blue {
  background: rgba(0, 6, 184, 0.59);
}

@media screen and (max-width: 919px) {
  .TripCard {
    flex-direction: column;
    border-radius: 0 0 5px 5px;
    position: relative;
    top: 34px;
    gap: 25px;
  }
  .TripCard .TripCard__locations {
    align-items: flex-start;
  }
  .TripCard .TripCard__locations .TripCard__group {
    width: auto;
    max-width: none;
    align-items: flex-start;
  }
  .TripCard .TripCard__locations .TripCard__group .TripCard__info {
    font-size: 16px;
    font-weight: 600;
  }
  .TripCard .TripCard__locations .TripCard__group .TripCard__info:nth-child(2) {
    overflow-y: hidden;
    overflow-x: visible;
    height: 18px;
    order: -1;
    font-size: 18px;
    text-align: left;
    line-height: 100%;
  }
  .TripCard .TripCard__locations .TripCard__group .TripCard__info .TripCard__info_city {
    display: inline;
  }
  .TripCard .TripCard__controll .TripCard__controll-btn-place {
    flex-wrap: wrap;
  }
  .TripCard .TripCard__controll .TripCard__headline {
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 5px 5px 0px 0px;
    background: #353535;
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    transform: translate(0, -100%);
    -webkit-transform: translate(0, -100%);
    -moz-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    -o-transform: translate(0, -100%);
  }
}
@media screen and (max-width: 364px) {
  .TripCard {
    flex-direction: column;
    border-radius: 0 0 5px 5px;
    position: relative;
    top: 34px;
    gap: 25px;
  }
  .TripCard .TripCard__locations {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
  .TripCard .TripCard__locations .TripCard__sign {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }
  .TripCard .TripCard__locations .TripCard__group {
    width: 100%;
    max-width: none;
    align-items: flex-start;
  }
  .TripCard .TripCard__locations .TripCard__group .TripCard__info {
    font-size: 16px;
    font-weight: 600;
  }
  .TripCard .TripCard__locations .TripCard__group .TripCard__info:nth-child(2) {
    overflow-y: visible;
    overflow-x: visible;
    height: auto;
    order: -1;
    font-size: 18px;
    text-align: left;
    line-height: 100%;
  }
  .TripCard .TripCard__locations .TripCard__group .TripCard__info .TripCard__info_city {
    display: inline;
  }
}