.PassangerLabel .PassangerLabel__input-group .PassangerLabel__input {
  width: 100%;
  height: 42px;
  padding: 10px 15px;
  color: #353535;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #8B8B8B;
  background: #FFF;
  outline: none;
}

.PassangerLabel .PassangerLabel__input-group .PassangerLabel__input-label {
  position: absolute;
  left: 10px;
  top: 0;
  background: #ffffff;
  padding: 0 5px;
  transform: translate(0, -50%);
  color: #646464;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

.PassangerLabel {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.PassangerLabel .PassangerLabel__head {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.PassangerLabel .PassangerLabel__input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
}