.Account {
  width: 100%;
  min-height: 100vh;
  margin-top: 70px;
  padding: 60px 50px;
  display: flex;
  gap: 30px;
  align-items: flex-start;
  justify-content: flex-start;
}
.Account a {
  text-decoration: none;
}
.Account * {
  font-family: "Montserrat", sans-serif;
}
.Account .Account__menu {
  min-width: 272px;
  width: auto;
  padding: 20px 25px;
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Account .Account__menu .Account__menu-head {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.Account .Account__menu .Account__menu-head .Account__menu-headline {
  text-align: left;
  color: #00274A;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.Account .Account__menu .Account__menu-head .Account__logout-btn {
  width: 100%;
  height: auto;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  background: #FFCB46;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: #353535;
  font-size: calc(12px + 0.3vw);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.Account .Account__menu .Account__menu-head .Account__logout-btn img {
  height: 25px;
  aspect-ratio: 1/1;
  object-fit: contain;
  pointer-events: none;
}
.Account .Account__menu .Account__menu-head::after {
  content: "";
  height: 1px;
  width: 100%;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 50%;
  border-radius: 2px;
  background: #D9D9D9;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
}
.Account .Account__menu .Account__menu-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  justify-content: flex-start;
}
.Account .Account__menu .Account__menu-nav .Account__menu-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #353535;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: font 0.3s;
  -webkit-transition: font 0.3s;
  -moz-transition: font 0.3s;
  -ms-transition: font 0.3s;
  -o-transition: font 0.3s;
}
.Account .Account__menu .Account__menu-nav .Account__menu-btn_selected {
  font-weight: 600;
  color: #00274A;
}
.Account .Account__menu .Account__menu-nav .Account__menu-btn img {
  width: 30px;
  aspect-ratio: 1/1;
  object-fit: contain;
}
.Account_admin .Account__menu .Account__menu-nav {
  align-items: center;
}

@media screen and (max-width: 1210px) {
  .Account {
    flex-direction: column;
  }
  .Account .Account__menu {
    width: 100%;
  }
}
@media screen and (max-width: 919px) {
  .Account {
    margin-top: 50px;
    padding: 20px;
    min-height: auto;
    background: #FFFFFF;
    flex-direction: column;
  }
  .Account_admin {
    width: 100%;
    background: transparent;
  }
  .Account .Account__menu {
    width: 100%;
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin-bottom: 20px;
  }
  .Account .Account__menu .Account__menu-head .Account__menu-headline {
    color: #353535;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  .Account .Account__menu .Account__menu-nav {
    display: none;
  }
}