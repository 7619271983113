.BookingMenu {
  width: 100%;
  padding: 25px;
  border-radius: 5px;
  border: 1px solid #AEAEAE;
  background: #FFF;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: -14px;
  gap: 50px;
  z-index: 2;
}
.BookingMenu > div {
  width: 33%;
}

@media screen and (max-width: 919px) {
  .BookingMenu {
    flex-direction: column;
  }
  .BookingMenu > div {
    width: 100%;
  }
}