.Home {
  width: 100%;
  min-height: calc(100vh - 70px);
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 25px;
  /* Style the calendar container */
  /* Style the calendar header */
  /* Style the month and year dropdowns */
  /* Style the days of the week */
  /* Style the individual days in the calendar */
  /* Style the selected day */
  /* Style the date input icon */
  /* Additional styles for better alignment of the date input icon */
}
.Home .react-datepicker__month-container {
  z-index: 11;
  border-radius: 5px;
  border: 1px solid #CECECE;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
}
.Home .react-datepicker__month-container * {
  font-family: "Montserrat", sans-serif;
}
.Home .react-datepicker__header {
  background-color: #ffffff;
  padding: 10px;
}
.Home .react-datepicker__current-month,
.Home .react-datepicker__navigation {
  font-size: 16px;
  color: #333;
}
.Home .react-datepicker__day-name {
  color: #666;
  font-weight: 700;
}
.Home .react-datepicker__day {
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.Home .react-datepicker__day:hover {
  background-color: #f0f0f0;
}
.Home .react-datepicker__day--today {
  font-weight: bold;
}
.Home .react-datepicker__day--selected {
  background-color: transparent;
  border: 3px solid #FFCB46;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.Home .react-datepicker-wrapper > .react-datepicker__input-container {
  position: relative;
}
.Home .react-datepicker__input-container > input {
  padding-right: 30px;
  /* Add space for the icon */
}
.Home .react-datepicker__input-container::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  /* Replace with your custom icon */
  background-size: cover;
}
.Home * {
  font-family: "Roboto", sans-serif;
}
.Home:has(.SearchingResult) {
  padding: 25px 150px;
}
.Home .Home__bgImage {
  width: 100%;
  height: calc(100vh - 70px);
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 0;
  user-select: none;
  pointer-events: none;
}
.Home .Home__head-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.Home .Home__head-group .Home__headline {
  z-index: 3;
  color: #FFFFFF;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4745098039);
}
.Home .Home__booking {
  width: auto;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 15px;
  border-radius: 5px;
  border: 1px solid #BDBDBD;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 15px;
  z-index: 6;
}
.Home .Home__booking_full {
  width: 100%;
}
.Home .Home__booking .Home__booking-caption {
  color: #646464;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  margin-left: 10px;
  z-index: 4;
}
.Home .Home__booking .react-datepicker-wrapper {
  position: relative;
}
.Home .Home__booking .Home__booking-place {
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: center;
}
.Home .Home__booking .Home__booking-place .Home__booking-place-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 9px;
}
.Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select {
  width: 250px;
  background-repeat: no-repeat, no-repeat;
  background-position: 12px center, calc(100% - 24px) center;
  background-size: 24px 24px, 13px 7px;
  padding: 8px 21px 8px 42px;
  border-radius: 6px 0px 0px 6px;
  border: 1px solid #8B8B8B;
  background-color: #FFFFFF;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select optgroup option {
  padding: 5px 10px;
}
.Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select optgroup option:checked,
.Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select optgroup option:hover {
  background-color: #eeeeee;
}
.Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select#Home__to-select {
  border-radius: 0 6px 6px 0;
  -webkit-border-radius: 0 6px 6px 0;
  -moz-border-radius: 0 6px 6px 0;
  -ms-border-radius: 0 6px 6px 0;
  -o-border-radius: 0 6px 6px 0;
  background-position: 12px center, calc(100% - 12px) center;
}
.Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select::-webkit-scrollbar {
  width: 8px;
}
.Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select::-webkit-scrollbar-track {
  border: 1px solid #BEBEBE;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #D9D9D9;
}
.Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
.Home .Home__booking .Home__booking-place .Home__booking-places-switch {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 100%;
  border: 1px solid #C8C8C8;
  outline: none;
  background: #FFF;
  z-index: 3;
  transform: translate(-50%, calc(-100% - 4px));
  -webkit-transform: translate(-50%, calc(-100% - 4px));
  -moz-transform: translate(-50%, calc(-100% - 4px));
  -ms-transform: translate(-50%, calc(-100% - 4px));
  -o-transform: translate(-50%, calc(-100% - 4px));
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.Home .Home__booking .Home__booking-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 9px;
}
.Home .Home__booking .Home__booking-group .react-datepicker-wrapper {
  width: 100%;
}
.Home .Home__booking .Home__booking-group .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}
.Home .Home__booking .Home__booking-group .Home__booking-datepiacker-wrapper {
  width: 100%;
}
.Home .Home__booking .Home__booking-group div .react-datepicker-wrapper .react-datepicker__input-container .Home__booking-date {
  width: 170px;
  color-scheme: only light;
  accent-color: #ffcb46;
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 24px 24px;
  padding: 8px 42px;
  border-radius: 6px;
  border: 1px solid #8B8B8B;
  background-color: #FFFFFF;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.Home .Home__booking .Home__booking-group div .react-datepicker-wrapper .react-datepicker__input-container .Home__booking-date::selection {
  background-color: #ffcb46;
  color: #ffffff;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select {
  width: 225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-label {
  width: 100%;
  color: #353535;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #8B8B8B;
  background-color: #FFFFFF;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-label .Home__booking-select-open-btn {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-label .Home__booking-select-open-btn img {
  height: 7px;
  width: auto;
  user-select: none;
  pointer-events: none;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options {
  display: none;
  opacity: 0;
  width: 100%;
  position: absolute;
  padding: 8px 12px;
  top: calc(100% + 10px);
  left: 0;
  background: #ffffff;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 25px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options .Home__booking-select-option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options .Home__booking-select-option .Home__booking-option-label {
  color: #353535;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options .Home__booking-select-option .Home__booking-select-btn {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options .Home__booking-select-option .Home__booking-select-btn img {
  height: 30px;
  aspect-ratio: 1/1;
  width: auto;
  pointer-events: none;
  user-select: none;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options .Home__booking-passangers-controll {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options .Home__booking-passangers-controll .Home__booking-passangers-controll-btn {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  color: #ffcb46;
}
.Home .Home__booking .Home__booking-search {
  padding: 9px 52px;
  color: #353535;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border-radius: 6px;
  border: 1px solid #FFCB46;
  background: #FFCB46;
  outline: none;
  cursor: pointer;
  margin-left: 40px;
}

@media screen and (max-width: 1488px) {
  .Home:has(.SearchingResult) {
    padding: 25px;
  }
}
@media screen and (max-width: 1178px) {
  .Home .Home__head-group {
    margin-top: 0;
  }
  .Home .Home__head-group .Home__bgImage {
    height: 160px;
  }
  .Home .Home__booking {
    margin: 25px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  .Home .Home__booking .Home__booking-group {
    width: 100%;
  }
  .Home .Home__booking .Home__booking-group div .react-datepicker-wrapper .react-datepicker__input-container .Home__booking-date {
    width: 100%;
  }
  .Home .Home__booking .Home__booking-group div .react-datepicker-wrapper .react-datepicker__input-container .Home__booking-date:placeholder {
    color: #141414;
  }
  .Home .Home__booking .Home__booking-group .Home__booking-passangers-select {
    width: 100%;
  }
  .Home .Home__booking .Home__booking-search {
    margin-left: 0;
    margin-top: 15px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #FFCB46;
    background: #FFCB46;
    padding: 15px;
  }
}
@media screen and (max-width: 919px) {
  .Home {
    min-height: min(750px, 100vh - 50px);
    margin-top: 50px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .Home:has(.SearchingResult) {
    padding: 25px;
  }
  .Home .Home__bgImage {
    width: calc(100% + 100px);
    height: 200px;
    position: relative;
    top: -25px;
    left: -25px;
  }
  .Home .Home__head-group {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: -100px;
  }
  .Home .Home__head-group .Home__headline {
    z-index: 2;
    color: #FFF;
    font-size: calc(25px + 0.8vw);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    top: -125px;
  }
  .Home .Home__booking {
    width: calc(100% + 50px);
    border: none;
    box-shadow: none;
    background: transparent;
    align-items: flex-start;
    background: #ffffff;
    margin-top: -25px;
    position: relative;
    left: -25px;
  }
  .Home .Home__booking .Home__booking-place {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .Home .Home__booking .Home__booking-place .Home__booking-place-item {
    width: 100%;
  }
  .Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-caption {
    margin-bottom: -13px;
    z-index: 1;
    background: #ffffff;
    color: #646464;
    font-size: 14.4px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding: 0 5px;
  }
  .Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select {
    height: 44px;
    width: 100%;
    border-radius: 6px;
    background-position: 12px center, calc(100% - 12px) center;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }
  .Home .Home__booking .Home__booking-place .Home__booking-place-item .Home__booking-select#Home__to-select {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }
  .Home .Home__booking .Home__booking-place .Home__booking-places-switch {
    height: 36px;
    width: 36px;
    top: calc(50% + 5px);
    left: auto;
    right: 15px;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
  }
  .Home .Home__booking .Home__booking-place .Home__booking-places-switch img {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }
  .Home .Home__booking .Home__booking-group .Home__booking-caption {
    margin-bottom: -13px;
    z-index: 1;
    background: #ffffff;
    color: #646464;
    font-size: 14.4px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding: 0 5px;
  }
  .Home .Home__booking .Home__booking-group div .react-datepicker-wrapper .react-datepicker__input-container .Home__booking-date {
    height: 44px;
  }
  .Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-label {
    height: 44px;
    box-shadow: none;
  }
  .Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options {
    outline: 1px solid #8B8B8B;
  }
  .Home .Home__booking .Home__booking-group .Home__booking-passangers-select .Home__booking-select-options::before {
    filter: drop-shadow(0 1px 0px #ffffff) drop-shadow(0 -1px 0 #8B8B8B);
    -webkit-filter: drop-shadow(0 1px 0px #ffffff) drop-shadow(0 -1px 0 #8B8B8B);
    transform: translate(0, 2px);
    -webkit-transform: translate(0, 2px);
    -moz-transform: translate(0, 2px);
    -ms-transform: translate(0, 2px);
    -o-transform: translate(0, 2px);
  }
  .Home .Home__booking-search {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}