.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 378px 15px 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  z-index: 20;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}
.Header a {
  width: fit-content;
  height: fit-content;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Header .Header__logo {
  height: 50px;
  width: auto;
  object-fit: contain;
  user-select: none;
}
.Header .Header__logo_mobile {
  display: none;
}
.Header .Header__nav-wrap {
  display: flex;
  gap: 75px;
  align-items: center;
  justify-content: center;
}
.Header .Header__nav-wrap .Header__nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Header .Header__nav-wrap .Header__nav .Header__nav-list {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  list-style: none;
}
.Header .Header__nav-wrap .Header__nav .Header__nav-list .Header__nav-list-item {
  cursor: pointer;
  user-select: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Header .Header__nav-wrap .Header__nav .Header__nav-list .Header__nav-list-item a {
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  font-family: "Heebo", sans-serif;
  letter-spacing: 0.5px;
}
.Header .Header__nav-wrap .Header__nav .Header__nav-list .Header__nav-list-item_selected {
  font-weight: 700;
  color: #FFCB46;
}
.Header .Header__nav-wrap .Header__nav .Header__nav-list .Header__nav-list-item_selected a {
  font-weight: 700;
  color: #FFCB46;
}
.Header .Header__nav-wrap .Header__contacts {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
}
.Header .Header__nav-wrap .Header__contacts .Header__contacts-item {
  height: 38px;
  aspect-ratio: 1/1;
}
.Header .Header__nav-wrap .Header__contacts .Header__contacts-item .Header__contacts-item-image {
  width: 100%;
  width: 100%;
  object-fit: contain;
}
.Header .Header__nav-wrap .Header__contacts .Header__phone {
  height: 100%;
  background: #FFCB46;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 150px;
}
.Header .Header__nav-wrap .Header__contacts .Header__phone .Header__phone-icon-wrapper {
  height: 38px;
  aspect-ratio: 1/1;
  position: relative;
  left: -20px;
  padding: 10px;
  background: #FFCB46;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.Header .Header__nav-wrap .Header__contacts .Header__phone .Header__phone-icon-wrapper .Header__phone-icon {
  height: 25px;
  width: 25px;
  object-fit: contain;
}
.Header .Header__nav-wrap .Header__contacts .Header__phone .Header__phone-info-wrapper {
  width: 175px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: -50px;
}
.Header .Header__nav-wrap .Header__contacts .Header__phone .Header__phone-info-wrapper .Header__headline {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  line-height: 100%;
  text-align: center;
  font-family: "Barlow", sans-serif;
}
.Header .Header__nav-wrap .Header__contacts .Header__phone .Header__phone-info-wrapper .Header__phone-text {
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  font-family: "Heebo", sans-serif;
}
.Header .Header__nav-open-btn {
  width: 35px;
  aspect-ratio: 2/1;
  border: none;
  display: none;
  background: transparent;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  outline: none;
}
.Header .Header__nav-open-btn .Header__nav-open-btn-visual {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: #ffffff;
  position: absolute;
  left: 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.Header .Header__nav-open-btn .Header__nav-open-btn-visual:nth-child(2) {
  top: 50%;
}
.Header .Header__nav-open-btn .Header__nav-open-btn-visual:first-child {
  top: 0;
}
.Header .Header__nav-open-btn .Header__nav-open-btn-visual:last-child {
  top: 100%;
}
.Header .Header__nav-open-btn_close .Header__nav-open-btn-visual:nth-child(2) {
  opacity: 0;
}
.Header .Header__nav-open-btn_close .Header__nav-open-btn-visual:first-child {
  top: 50%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.Header .Header__nav-open-btn_close .Header__nav-open-btn-visual:last-child {
  top: 50%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

@media screen and (max-width: 1358px) {
  .Header {
    padding: 15px 258px 15px 25px;
    gap: 25px;
  }
  .Header .Header__nav-wrap {
    gap: 25px;
  }
  .Header .Header__nav-wrap .Header__nav .Header__nav-list {
    gap: 15px;
  }
  .Header .Header__nav-wrap .Header__contacts .Header__phone {
    padding-right: 25px;
  }
  .Header .Header__nav-wrap .Header__contacts .Header__phone .Header__phone-info-wrapper {
    margin-left: -50px;
  }
}
@media screen and (max-width: 919px) {
  .Header {
    height: 50px;
    padding: 25px;
    background: #FFCB46;
    justify-content: space-between;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: background 0.3s, box-shadow 0.3s;
    -webkit-transition: background 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, box-shadow 0.3s;
    -ms-transition: background 0.3s, box-shadow 0.3s;
    -o-transition: background 0.3s, box-shadow 0.3s;
  }
  .Header:has(.Header__nav-open-btn_close) {
    background: #ffffff;
    box-shadow: 0 5px 15px rgba(20, 20, 20, 0.3137254902);
  }
  .Header:has(.Header__nav-open-btn_close) .Header__nav-open-btn .Header__nav-open-btn-visual {
    background: #333333;
  }
  .Header .Header__logo {
    display: none;
  }
  .Header .Header__logo_mobile {
    height: 35px;
    aspect-ratio: 1/1;
    object-fit: contain;
    display: block;
  }
  .Header .Header__nav-wrap {
    display: none;
  }
  .Header .Header__nav-open-btn {
    display: flex;
  }
}