.Alert {
  width: 100%;
  height: 100vh;
  z-index: 51;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(20, 20, 20, 0.8117647059);
  backdrop-filter: blur(2px);
  padding: 25px;
  display: none;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Alert .Alert__card {
  max-width: 600px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.Alert .Alert__card * {
  font-family: "Montserrat", sans-serif;
}
.Alert .Alert__card .Alert__message {
  font-size: calc(18px + 0.5vw);
  font-weight: 700;
  color: #333333;
  line-height: 120%;
  text-align: center;
}
.Alert .Alert__card .Alert__button {
  padding: 5px 25px;
  border: none;
  outline: none;
  cursor: pointer;
  background: #ffb246;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-size: calc(12px + 0.5vw);
  font-weight: 700;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}