@font-face {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 100;
    src: url("./Barlow-ExtraLight.ttf");
}
@font-face {
    font-family: "Barlow";
    font-style: italic;
    font-weight: 100;
    src: url("./Barlow-ExtraLightItalic.ttf");
}

@font-face {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 200;
    src: url("./Barlow-Light.ttf");
}
@font-face {
    font-family: "Barlow";
    font-style: italic;
    font-weight: 200;
    src: url("./Barlow-LightItalic.ttf");
}

@font-face {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 300;
    src: url("./Barlow-Thin.ttf");
}
@font-face {
    font-family: "Barlow";
    font-style: italic;
    font-weight: 300;
    src: url("./Barlow-ThinItalic.ttf");
}

@font-face {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    src: url("./Barlow-Regular.ttf");
}
@font-face {
    font-family: "Barlow";
    font-style: italic;
    font-weight: 400;
    src: url("./Barlow-Italic.ttf");
}

@font-face {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    src: url("./Barlow-Medium.ttf");
}
@font-face {
    font-family: "Barlow";
    font-style: italic;
    font-weight: 500;
    src: url("./Barlow-MediumItalic.ttf");
}

@font-face {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    src: url("./Barlow-SemiBold.ttf");
}
@font-face {
    font-family: "Barlow";
    font-style: italic;
    font-weight: 600;
    src: url("./Barlow-SemiBoldItalic.ttf");
}

@font-face {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    src: url("./Barlow-Bold.ttf");
}
@font-face {
    font-family: "Barlow";
    font-style: italic;
    font-weight: 700;
    src: url("./Barlow-BoldItalic.ttf");
}

@font-face {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 800;
    src: url("./Barlow-ExtraBold.ttf");
}
@font-face {
    font-family: "Barlow";
    font-style: italic;
    font-weight: 800;
    src: url("./Barlow-ExtraBoldItalic.ttf");
}

@font-face {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 900;
    src: url("./Barlow-Black.ttf");
}
@font-face {
    font-family: "Barlow";
    font-style: italic;
    font-weight: 900;
    src: url("./Barlow-BlackItalic.ttf");
}
