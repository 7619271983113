.PersonalInfo {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 35px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.PersonalInfo .PersonalInfo__headline {
  margin-bottom: 10px;
  color: #353535;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.PersonalInfo .PersonalInfo__fields {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.PersonalInfo .PersonalInfo__fields .PersonalInfo__raw {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 30px;
}
.PersonalInfo .PersonalInfo__fields .PersonalInfo__raw .PersonalInfo__input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
}
.PersonalInfo .PersonalInfo__fields .PersonalInfo__raw .PersonalInfo__input-group .PersonalInfo__input-label {
  color: #646464;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.PersonalInfo .PersonalInfo__fields .PersonalInfo__raw .PersonalInfo__input-group .PersonalInfo__input {
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #FFFFFF;
  outline: none;
  color: #353535;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PersonalInfo .PersonalInfo__btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.PersonalInfo .PersonalInfo__btn-container .PersonalInfo__btn {
  width: 200px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #FFCB46;
  background: #FFCB46;
  outline: none;
  color: #353535;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  text-transform: uppercase;
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}
.PersonalInfo .PersonalInfo__btn-container .PersonalInfo__btn_outlined {
  background: transparent;
  border: 3px solid #FFCB46;
  color: #FFCB46;
}

@media screen and (max-width: 919px) {
  .PersonalInfo {
    width: 100%;
    min-width: auto;
    align-items: center;
    padding: 20px;
    gap: 20px;
  }
  .PersonalInfo .PersonalInfo__headline {
    text-align: center;
    font-size: 24px;
    margin-bottom: 0;
  }
  .PersonalInfo .PersonalInfo__fields {
    width: 100%;
    flex-direction: column;
  }
  .PersonalInfo .PersonalInfo__fields .PersonalInfo__raw {
    width: 100%;
    flex-direction: column;
    row-gap: 10px;
  }
  .PersonalInfo .PersonalInfo__fields .PersonalInfo__raw .PersonalInfo__input-group {
    width: 100%;
  }
  .PersonalInfo .PersonalInfo__fields .PersonalInfo__raw .PersonalInfo__input-group .PersonalInfo__input {
    width: 100%;
  }
  .PersonalInfo .PersonalInfo__btn-container {
    align-items: center;
    justify-content: center;
  }
  .PersonalInfo .PersonalInfo__btn-container .PersonalInfo__btn {
    height: 35px;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
  }
}