.PhoneMenu {
  display: none;
}

@media screen and (max-width: 919px) {
  .PhoneMenu {
    height: 60px;
    z-index: 9;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 6px 30px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background: #FFCB46;
    box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1450980392);
  }
  .PhoneMenu .PhoneMenu-btn {
    width: 75px;
    border: none;
    outline: none;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  .PhoneMenu .PhoneMenu-btn .PhoneMenu-icon {
    height: 25px;
    width: auto;
    object-fit: contain;
  }
  .PhoneMenu .PhoneMenu-btn .PhoneMenu-text {
    color: #353535;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .PhoneMenu .PhoneMenu-btn_selected {
    font-weight: 600;
  }
  .PhoneMenu .PhoneMenu-btn_selected .PhoneMenu-text {
    font-weight: 600;
  }
  .PhoneMenu a {
    text-decoration: none;
  }
}