@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Black.ttf') format('truetype');
    font-weight: 900; /* Black */
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900; /* Black */
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Light.ttf') format('truetype');
    font-weight: 300; /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300; /* Light */
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Medium.ttf') format('truetype');
    font-weight: 500; /* Medium */
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500; /* Medium */
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Thin.ttf') format('truetype');
    font-weight: 100; /* Thin */
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100; /* Thin */
    font-style: italic;
}