@font-face {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400; /* Regular */
    src: local('Arial Regular'), url('ARIAL.woff') format('woff');
}

@font-face {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700; /* Bold */
    src: local('Arial Bold'), url('ARIALBD.woff') format('woff');
}

@font-face {
    font-family: 'Arial';
    font-style: italic;
    font-weight: 400; /* Italic */
    src: local('Arial Italic'), url('ARIALI.woff') format('woff');
}

@font-face {
    font-family: 'Arial';
    font-style: italic;
    font-weight: 700; /* Bold Italic */
    src: local('Arial Bold Italic'), url('ARIALBI.woff') format('woff');
}