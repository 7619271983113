.UsersControl {
  width: 100%;
  max-width: 612px;
  padding: 15px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.UsersControl .Authorization {
  width: 100%;
  margin: 0;
  padding: 0;
  position: static;
}
.UsersControl .Authorization .Authorization__container {
  width: 100%;
  border-radius: 20px;
}
.UsersControl .Authorization .Authorization__container .Authorization__input-wrap {
  width: 100%;
}
.UsersControl .Authorization .Authorization__container .Authorization__input-wrap .Authorization__input {
  width: 100%;
}
.UsersControl .Authorization .Authorization__container .Authorization__action_cancel {
  background: rgb(221, 32, 32);
}
.UsersControl .UsersControl__headline {
  width: 100%;
  color: #353535;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.UsersControl .UsersControll__search {
  width: 100%;
  padding: 8px 50px;
  background-size: 31px 23px;
  background-color: #FFF;
  background-position: 12px center;
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 1px solid #818181;
  outline: none;
  cursor: pointer;
}
.UsersControl .UsersControl__results {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.UsersControl .UsersControl__results .UsersControl__user {
  width: 100%;
  padding: 8px;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  color: #353535;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #8B8B8B;
  background: #FFF;
}
.UsersControl .UsersControl__results .UsersControll__user-btn-place {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.UsersControl .UsersControl__results .UsersControll__user-btn-place .UsersControl__edit-button {
  aspect-ratio: 1/1;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EFEFEF;
  border: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
}
.UsersControl .UsersControl__results .UsersControll__user-btn-place .UsersControl__edit-button:last-child {
  background: #c3c3c3;
}
.UsersControl .UsersControl__results .UsersControll__user-btn-place .UsersControl__edit-button img {
  height: 18px;
  width: auto;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}
.UsersControl .UsersControl__pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #797979;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.UsersControl .UsersControl__pagination .UsersControl__pagination-btn {
  cursor: pointer;
  color: #797979;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.UsersControl .UsersControl__pagination .UsersControl__pagination-btn::after {
  content: ",";
  color: #797979;
  margin-right: 3px;
}
.UsersControl .UsersControl__pagination .UsersControl__pagination-btn:last-child::after {
  display: none;
}
.UsersControl .UsersControl__pagination .UsersControl__pagination-btn_active {
  color: #FFCB46;
}

@media screen and (max-width: 919px) {
  .UsersControl {
    width: 100%;
    min-height: calc(100vh - 50px);
    justify-content: flex-start;
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
  .UsersControl .Authorization {
    width: 100%;
    height: auto;
    min-height: auto;
    border-radius: 5px;
    border: 1px solid #353535;
    background: #FFF;
  }
  .UsersControl .Authorization .Authorization__container {
    width: 100%;
    border-radius: 5px;
  }
  .UsersControl .UsersControll__search {
    width: 100%;
  }
  .UsersControl .UsersControl__headline {
    display: none;
  }
  .UsersControl .UsersControl__results .UsersControl__user {
    height: auto;
    padding: 5px;
    font-size: 20px;
  }
}