.Tickets {
  width: 100%;
  padding: 30px 35px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 35px;
}
.Tickets * {
  font-family: "Roboto", sans-serif;
}
.Tickets .Tickets__headline {
  color: #00274A;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Tickets .Tickets__container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: column;
}

@media screen and (max-width: 919px) {
  .Tickets {
    width: 100%;
    padding: 20px;
    gap: 20px;
  }
  .Tickets .Tickets__headline {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
}