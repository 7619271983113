.AllUsersData .AllUsersData__search .AllUsersData__search-input-container .AllUsersData__search-input {
  width: 100%;
  height: 42px;
  padding: 10px 15px;
  color: #353535;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #8B8B8B;
  background: #FFF;
  outline: none;
}

.AllUsersData .AllUsersData__search .AllUsersData__search-input-container .AllUsersData__search-input-label {
  position: absolute;
  left: 10px;
  top: 0;
  background: #ffffff;
  padding: 0 5px;
  transform: translate(0, -50%);
  color: #646464;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

.AllUsersData {
  width: 100%;
  padding: 25px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.AllUsersData .AllUsersData__eror-msg {
  width: 100%;
  font-weight: 700;
  color: #bdbdbd;
  text-align: center;
  font-size: calc(16px + 0.3vw);
}
.AllUsersData * {
  font-family: "Montserrat", sans-serif;
}
.AllUsersData .AllUsersData__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.AllUsersData .AllUsersData__head .AllUsersData__headline {
  color: #353535;
  text-align: left;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.AllUsersData .AllUsersData__head .AllUsersData__btn {
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  background: #FFCB46;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
}
.AllUsersData .AllUsersData__head .AllUsersData__btn img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.AllUsersData .AllUsersData__search {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.AllUsersData .AllUsersData__search .AllUsersData__search-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.AllUsersData .AllUserData__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
}
.AllUsersData .AllUserData__container .AllUserData__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.AllUsersData .AllUserData__container .AllUsersData__user {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}
.AllUsersData .AllUserData__container .AllUsersData__user .AllUserData__subheadline {
  color: #353535;
  text-align: left;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.AllUsersData .AllUserData__container .AllUsersData__user .AllUserData__user-field {
  display: inline;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-weight: 700;
}
.AllUsersData .AllUserData__container .AllUsersData__user .AllUserData__user-field img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.AllUsersData .AllUserData__container .AllUsersData__user .AllUserData__user-field * {
  display: inline-block;
  vertical-align: middle;
}
.AllUsersData .AllUserData__container .AllUsersData__user .AllUserData__user-field .AllUserData__thin {
  font-weight: 400;
  color: #333333;
}

@media screen and (max-width: 919px) {
  .AllUsersData .AllUsersData__head {
    gap: 15px;
  }
  .AllUsersData .AllUsersData__head .AllUsersData__headline {
    font-size: 25px;
  }
  .AllUsersData .AllUserData__container .AllUsersData__user .AllUserData__subheadline {
    font-size: 18px;
  }
  .AllUsersData .AllUserData__container .AllUsersData__user .AllUserData__user-field {
    font-size: 13px;
  }
}