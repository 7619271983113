.Authorization {
  width: 100%;
  min-height: 100vh;
  margin-top: 70px;
  padding: 80px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.Authorization .Authorization__headline {
  width: 100%;
  text-align: center;
  color: #353535;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Authorization * {
  font-family: "Montserrat", sans-serif;
}
.Authorization .Authorization__container {
  width: 333px;
  padding: 15px 20px;
  border-radius: 0 0 20px 20px;
  background: #FFF;
  -webkit-border-radius: 0 0 20px 20px;
  -moz-border-radius: 0 0 20px 20px;
  -ms-border-radius: 0 0 20px 20px;
  -o-border-radius: 0 0 20px 20px;
  position: relative;
  display: flex;
  gap: 25px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Authorization .Authorization__container .Authorization__select {
  width: 100%;
  position: absolute;
  bottom: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  -o-border-radius: 20px 20px 0 0;
}
.Authorization .Authorization__container .Authorization__select .Authorization__option {
  padding: 9px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #FFCB46;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  cursor: pointer;
}
.Authorization .Authorization__container .Authorization__select .Authorization__option.Authorization__option_selected {
  background-color: #FFFFFF;
  color: #353535;
  z-index: 0;
}
.Authorization .Authorization__container .Authorization__select .Authorization__option:first-child {
  width: 40%;
  justify-content: flex-start;
}
.Authorization .Authorization__container .Authorization__select .Authorization__option:first-child.Authorization__option_selected {
  z-index: 1;
}
.Authorization .Authorization__container .Authorization__select .Authorization__option:first-child.Authorization__option_selected::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 45px;
  background: #FFFFFF;
  left: 100%;
  clip-path: polygon(100% 0, 0 0, 0 100%);
}
.Authorization .Authorization__container .Authorization__select .Authorization__option:last-child {
  width: 60%;
  justify-content: flex-end;
}
.Authorization .Authorization__container .Authorization__select .Authorization__option:last-child.Authorization__option_selected {
  z-index: 1;
}
.Authorization .Authorization__container .Authorization__select .Authorization__option:last-child.Authorization__option_selected::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 45px;
  background: #FFFFFF;
  right: calc(100% - 1px);
  clip-path: polygon(100% 100%, 0 0, 100% 0);
}
.Authorization .Authorization__container .Authorization__input-wrap {
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Authorization .Authorization__container .Authorization__input-wrap .Authorization__input-label {
  width: 100%;
  color: #00274A;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Authorization .Authorization__container .Authorization__input-wrap .Authorization__input {
  width: 100%;
  height: 43px;
  border-radius: 10px;
  padding: 5px 15px;
  outline: none;
  border: 1px solid #D9D9D9;
  background: #FFF;
  color: #353535;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Authorization .Authorization__container .Authorization__action {
  width: 100%;
  height: 44px;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #FFCB46;
  border: none;
  outline: none;
  cursor: pointer;
}
.Authorization .Authorization__container .Authorization__action_outline {
  background: transparent;
  border: 3px solid #FFCB46;
  color: #FFCB46;
}
.Authorization .Authorization__container .Authorization__recover {
  width: 100%;
  text-align: right;
  color: #00274A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

@media screen and (max-width: 919px) {
  .Authorization {
    margin-top: 50px;
    padding: 60px 20px 15px 20px;
  }
  .Authorization .Authorization__container {
    width: 100%;
    background: #ffffff;
  }
}