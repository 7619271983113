.Modal {
  width: 100%;
  height: 100vh;
  z-index: 51;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(20, 20, 20, 0.8117647059);
  backdrop-filter: blur(2px);
  padding: 25px;
  display: none;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Modal .Modal__card {
  max-width: 600px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.Modal .Modal__card * {
  font-family: "Montserrat", sans-serif;
}
.Modal .Modal__card .Modal__message {
  width: 100%;
  font-size: calc(18px + 0.5vw);
  font-weight: 700;
  color: #333333;
  line-height: 120%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Modal .Modal__card .Modal__message .Modal__map {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  margin: 15px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1450980392);
  border-radius: 5px;
  flex-grow: 1;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.Modal .Modal__card .Modal__message .Modal__map > div {
  width: 100%;
  height: 500px;
}
.Modal .Modal__card .Modal__btn-place {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.Modal .Modal__card .Modal__btn-place .Modal__button {
  width: 100%;
  padding: 5px 25px;
  border: none;
  outline: none;
  cursor: pointer;
  background: #ffb246;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-size: calc(12px + 0.5vw);
  font-weight: 700;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.Modal .Modal__card .Modal__btn-place .Modal__button_confirm {
  background: rgba(48, 184, 0, 0.59);
}
.Modal .Modal__card .Modal__btn-place .Modal__button_reject {
  background: rgba(255, 10, 10, 0.75);
}

@media screen and (max-width: 919px) {
  .Modal .Modal__btn-place {
    flex-wrap: wrap;
  }
}