.FlightCard__opened {
  width: 100%;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  background: #FBFBFB;
  border: 1px solid #AEAEAE;
  border-top: none;
  border-radius: 0 0 6px 6px;
  padding: 30px 60px;
  margin-top: -26px;
  z-index: 4;
  position: relative;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  -ms-border-radius: 0 0 6px 6px;
  -o-border-radius: 0 0 6px 6px;
}
.FlightCard__opened * {
  font-family: "Roboto", sans-serif;
}
.FlightCard__opened::before {
  content: "";
  height: 1px;
  width: calc(100% - 60px);
  background: #D9D9D9;
  position: absolute;
  top: 0;
  left: 30px;
}
.FlightCard__opened .FlightCard__group {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.FlightCard__opened .FlightCard__group:nth-child(2n)::after {
  display: none;
}
.FlightCard__opened .FlightCard__group:nth-child(2n)::before {
  display: none;
}
.FlightCard__opened .FlightCard__group::after {
  content: "";
  height: 80px;
  width: 1px;
  border-radius: 2px;
  background: #D9D9D9;
  position: absolute;
  right: -25px;
}
.FlightCard__opened .FlightCard__group::before {
  content: "";
  height: 80px;
  width: 1px;
  border-radius: 2px;
  background: #D9D9D9;
  position: absolute;
  left: -25px;
}
.FlightCard__opened .FlightCard__group .FlightCard__text {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.FlightCard__opened .FlightCard__group .FlightCard__text_bold {
  color: #353535;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.FlightCard__opened .FlightCard__group .FlightCard__text_bigBold {
  color: #353535;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.FlightCard__opened .FlightCard__group .FlightCard__text_mediumBold {
  color: #353535;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 77.778% */
}

.FlightCard {
  width: 100%;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-radius: 6px;
  border: 1px solid #AEAEAE;
  background: #FBFBFB;
  z-index: 3;
}
.FlightCard .FlightCard__free-places {
  display: none;
}
.FlightCard.FlightCard_opened .FlightCard__group:nth-child(3)::after {
  display: none;
}
.FlightCard .FlightCard__info {
  white-space: pre-wrap;
  word-break: break-all;
  color: #353535;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.FlightCard .FlightCard__info_bold {
  color: #141414;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.FlightCard .FlightCard__info_bigBold {
  color: #353535;
  font-family: "Arial", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.FlightCard .FlightCard__info_bigBold .FlightCard__info_bold {
  color: #353535;
}
.FlightCard .FlightCard__group {
  width: 33%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.FlightCard .FlightCard__group::after {
  content: "";
  height: 80px;
  width: 1px;
  border-radius: 2px;
  background: #D9D9D9;
  position: absolute;
  right: -25px;
}
.FlightCard .FlightCard__locations {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.FlightCard .FlightCard__locations .FlightCard__arrow {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
.FlightCard .FlightCard__locations .FlightCard__location-column {
  width: 50%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.FlightCard .FlightCard__locations .FlightCard__location-column .FlightCard__info {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.FlightCard .FlightCard__locations .FlightCard__location-column .FlightCard__info span {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.FlightCard .FlightCard__locations .FlightCard__location-column .FlightCard__info span img {
  height: 20px;
  width: 20px;
  aspect-ratio: 1/1;
  user-select: none;
}
.FlightCard .FlightCard__book-btn {
  padding: 13px;
  border-radius: 5px;
  border: 1px solid #FFCB46;
  background: #FFCB46;
  outline: none;
  cursor: pointer;
}
.FlightCard .FlightCard__book-btn p {
  display: none;
}
.FlightCard .FlightCard__book-btn img {
  height: 24px;
  aspect-ratio: 1/1;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}
.FlightCard .FlightCard__summary {
  display: none;
}

@media screen and (max-width: 1104px) {
  .FlightCard__opened {
    display: none;
  }
  .FlightCard {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 15px;
    gap: 20px;
  }
  .FlightCard .FlightCard__group {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .FlightCard .FlightCard__group .FlightCard__info:first-child:not(.FlightCard__info_bold)::after {
    content: ":";
  }
  .FlightCard .FlightCard__group::after {
    display: none;
  }
  .FlightCard .FlightCard__group.FlightCard__group_uderlineonmobile::after {
    top: auto;
    display: block;
    bottom: -20px;
    right: 0;
    width: 100%;
    height: 1px;
  }
  .FlightCard .FlightCard__locations {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .FlightCard .FlightCard__locations .FlightCard__arrow {
    transform: rotate(90deg);
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 66.667% */
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }
  .FlightCard .FlightCard__locations .FlightCard__location-column {
    width: 100%;
  }
  .FlightCard .FlightCard__book-btn {
    align-self: center;
    padding: 5px 30px;
    background: #25A904;
    border: none;
  }
  .FlightCard .FlightCard__book-btn p {
    display: block;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 200%;
    pointer-events: none;
    user-select: none;
  }
  .FlightCard .FlightCard__book-btn img {
    display: none;
  }
  .FlightCard .FlightCard__summary {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }
  .FlightCard .FlightCard__summary .FlightCard__summary-raw {
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
  .FlightCard .FlightCard__summary .FlightCard__summary-raw .FlightCard__summary-sum {
    color: #353535;
    text-align: right;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .FlightCard .FlightCard__summary .FlightCard__summary-raw .FlightCard__summary-currency {
    color: #353535;
    text-align: right;
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 77.778% */
  }
  .FlightCard .FlightCard__summary .FlightCard__summary-info {
    color: #000000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }
}
@media screen and (max-width: 919px) {
  .FlightCard {
    position: relative;
  }
  .FlightCard .FlightCard__free-places {
    display: flex;
    position: absolute;
    top: 11px;
    right: 13px;
    color: #353535;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
}