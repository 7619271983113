.Ticket {
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  background: #FBFBFB;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.Ticket a {
  text-decoration: none;
}
.Ticket_active {
  border: 1px solid #FFCB46;
}
.Ticket_active .Ticket__action {
  background: rgba(255, 10, 10, 0.75);
  border: none;
  color: #FFF;
  cursor: pointer;
}
.Ticket_done {
  border: 1px solid #27C100;
}
.Ticket_done .Ticket__action {
  border: 1px solid #27C100;
  background: rgba(255, 255, 255, 0.75);
  color: #27C100;
}
.Ticket_canceled {
  border: 1px solid #FF0A0A;
}
.Ticket_canceled .Ticket__action {
  border: 1px solid #FF0A0A;
  background: rgba(255, 255, 255, 0.75);
  color: #FF0A0A;
}
.Ticket .Ticket__locations {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.Ticket .Ticket__group {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.Ticket .Ticket__group_arrow {
  width: auto;
  min-width: auto;
}
.Ticket .Ticket__group_location {
  width: 50%;
  align-items: flex-start;
}
.Ticket .Ticket__group .Ticket__info {
  width: 100%;
  color: #353535;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
}
.Ticket .Ticket__group .Ticket__info a {
  color: #353535;
  display: inline;
}
.Ticket .Ticket__group .Ticket__info a * {
  display: inline-block;
  vertical-align: middle;
}
.Ticket .Ticket__group .Ticket__info a img {
  height: 25px;
  width: 25px;
  object-fit: contain;
}
.Ticket .Ticket__group .Ticket__info_bold {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.Ticket .Ticket__group .Ticket__info_bigBold {
  color: #353535;
  text-align: center;
  font-family: "Arial", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.Ticket .Ticket__group .Ticket__info_sup {
  color: #353535;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  position: relative;
  top: -14px;
}
.Ticket .Ticket__group .Ticket__info_small {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.Ticket .Ticket__group .Ticket__sign {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 50% */
}
.Ticket .Ticket__action {
  height: 40px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 25px;
  border-radius: 5px;
  outline: none;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  font-family: "Arial", sans-serif;
}

@media screen and (max-width: 919px) {
  .Ticket {
    flex-direction: column;
    gap: 20px;
    padding: 10px 15px;
  }
  .Ticket .Ticket__locations {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .Ticket .Ticket__locations .Ticket__group {
    width: 100%;
    align-items: flex-start;
  }
  .Ticket .Ticket__locations .Ticket__group_location {
    width: auto;
    flex-basis: auto;
  }
  .Ticket .Ticket__locations .Ticket__sign {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }
  .Ticket .Ticket__group {
    width: 100%;
    align-items: center;
  }
  .Ticket .Ticket__group .Ticket__info_bigBold {
    font-size: 36px;
  }
  .Ticket .Ticket__group .Ticket__info_sup {
    top: -28px;
  }
}