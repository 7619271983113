.PlacesSet {
  display: flex;
  gap: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.PlacesSet .PlacesSet__headline {
  width: 100%;
  color: #0F0F0F;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.PlacesSet .PlacesSet__headline .PlacesSet__headline_number {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFCB46;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}