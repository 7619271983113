.BackControll {
  width: 100%;
  max-width: 612px;
  padding: 20px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.BackControll .BackControll__headline {
  width: 100%;
  color: #353535;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.BackControll .BackControll__container {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.BackControll .BackControll__container .BackControll__item {
  width: 100%;
  min-width: min(335px, 100vw - 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-head {
  width: 100%;
  position: relative;
  padding: 5px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #353535;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-head .BackControll__item-close-btn {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translate(0, -50%);
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-head .BackControll__item-close-btn img {
  height: 9px;
  object-fit: contain;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-content {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-content .BackControll__item-content-row {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: flex-end;
  justify-content: space-between;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-content .BackControll__item-content-row .BackControll__item-sign {
  align-self: flex-start;
  color: #353535;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-content .BackControll__item-content-row .BackControll__item-column {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-content .BackControll__item-content-row .BackControll__item-column .BackControll__item-info {
  color: #353535;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-content .BackControll__item-content-row .BackControll__item-column .BackControll__item-info_bigBold {
  color: #353535;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-content .BackControll__item-headline {
  color: #353535;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.BackControll .BackControll__container .BackControll__item .BackControll__item-content .BackControll__item-subheadline {
  color: #353535;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 1210px) {
  .BackControll {
    width: 100%;
  }
}
@media screen and (max-width: 919px) {
  .BackControll {
    width: 100%;
  }
  .BackControll .BackControll__container {
    width: 100%;
  }
  .BackControll .BackControll__container .BackControll__item {
    width: 100%;
    max-width: none;
  }
}