.TripEditor {
  width: 364px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.TripEditor .TripEditor__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.TripEditor .TripEditor__wrap .TripEditor__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.TripEditor .TripEditor__wrap .TripEditor__add-btn {
  width: 100%;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #353535;
  background: #FFF;
  color: #353535;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.TripEditor .TripEditor__wrap .TripEditor__add-btn img {
  height: 12px;
  object-fit: contain;
}
.TripEditor .TripEditor__card {
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #AEAEAE;
  background: #FFF;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  position: relative;
}
.TripEditor .TripEditor__card .TripEditor__headline {
  color: #353535;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.TripEditor .TripEditor__card .TripEditor__card-content {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.TripEditor .TripEditor__card .TripEditor__card-content .TripEditor__input-conatiner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.TripEditor .TripEditor__card .TripEditor__card-content .TripEditor__input-conatiner .TripEditor__map {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1450980392);
  border-radius: 5px;
  margin: 15px 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.TripEditor .TripEditor__card .TripEditor__card-content .TripEditor__input-conatiner .TripEditor__input-row {
  width: 100%;
  height: auto;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}
.TripEditor .TripEditor__card .TripEditor__card-content .TripEditor__input-conatiner .TripEditor__input-row .TripEditor__location-btn {
  height: 40px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #FFCB46;
  border: none;
  outline: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.TripEditor .TripEditor__card .TripEditor__card-content .TripEditor__input-conatiner .TripEditor__input-row .TripEditor__location-btn img {
  height: 25px;
  object-fit: contain;
  aspect-ratio: 1/1;
  pointer-events: none;
  user-select: none;
}
.TripEditor .TripEditor__card .TripEditor__card-content .TripEditor__input-conatiner .TripEditor__input-label {
  width: 100%;
  color: #353535;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.TripEditor .TripEditor__card .TripEditor__card-content .TripEditor__input-conatiner .TripEditor__input {
  width: 100%;
  height: 40px;
  color: #353535;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  outline: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.TripEditor .TripEditor__save-btn {
  height: 35px;
  width: 100%;
  border-radius: 5px;
  background: #FFCB46;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}
.TripEditor .TripEditor__save-btn_outlined {
  background: transparent;
  border: 2px solid #FFCB46;
  color: #FFCB46;
}

@media screen and (max-width: 919px) {
  .TripEditor {
    width: 100%;
  }
}