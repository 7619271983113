.SearchingResult {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
  margin-top: 20px;
  flex-direction: column;
  gap: 25px;
}
.SearchingResult .SearchingResult__info {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: calc(25px + 0.3vw);
  line-height: 125%;
  font-family: "Montserrat";
  color: #333333;
}
.SearchingResult .SearchingResult__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}