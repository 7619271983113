.TripsControll {
  width: 100%;
  padding: 25px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.TripsControll:has(.TripEditor), .TripsControll:has(.ViewTrip) {
  min-width: auto;
  width: auto;
}
.TripsControll .TripsControll__head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.TripsControll .TripsControll__head .TripsControll__headline {
  color: #353535;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.TripsControll .TripsControll__head .TripsControll__head-btn {
  min-height: 40px;
  width: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  color: #00274A;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.TripsControll .TripsControll__head .TripsControll__head-btn img {
  height: 14px;
  aspect-ratio: 1/1;
  object-fit: contain;
  pointer-events: none;
}
.TripsControll .TripsControll__container {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}

@media screen and (max-width: 919px) {
  .TripsControll {
    min-width: none;
    width: 100%;
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
  .TripsControll:has(.ViewTrip) {
    width: 100%;
    padding: 25px;
    background: #ffffff;
  }
  .TripsControll .TripsControll__head {
    margin-bottom: 50px;
  }
  .TripsControll .TripsControll__head .TripsControll__headline {
    display: none;
  }
  .TripsControll .TripsControll__head .TripsControll__head-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .TripsControll .TripsControll__container {
    gap: 85px;
    margin-bottom: 35px;
  }
}